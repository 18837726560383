<template >
  <v-container>
    <v-container max-width="1100" class="mx-auto">
      <h3>Atendimentos</h3>
      <v-divider class="my-2"></v-divider>

      <!-- CAMPO DE PESQUISA -->
      <v-row dense>
        <v-col cols="12" sm="12" md="2" lg="2" dense>
          <v-text-field prepend-inner-icon="mdi-account-group" v-model="codigo" outlined label="Cod." dense clearable
            @keydown.enter="get_query_id_paciente([codigo])" :loading="loading" type="number">
            <template v-slot:append>
              <v-progress-circular v-if="loading" indeterminate size="25" color="info"></v-progress-circular>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" dense>
          <v-text-field prepend-inner-icon="mdi-account-group" v-model="dt_nascimento" outlined label="Data de Nascimento"
            dense clearable v-mask="'##/##/####'" @keydown.enter="get_query_nome_niver()" :loading="loading">
            <template v-slot:append>
              <v-progress-circular v-if="loading" indeterminate size="25" color="info"></v-progress-circular>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" dense>
          <v-text-field prepend-inner-icon="mdi-account-group" v-model="nome" outlined label="Nome" dense
            placeholder="Nome" @keydown.enter="get_query_nome_niver()" :loading="loading" clearable>
            <template v-slot:append>
              <v-progress-circular v-if="loading" indeterminate size="25" color="info"></v-progress-circular>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="4" dense>
          <v-text-field prepend-inner-icon="mdi-account-group" v-model="cpf" outlined label="CPF" dense
            v-mask="'###.###.###-##'" @keydown.enter="get_query('get_paciente_m')" :loading="loading" clearable
            :rules="[(v) => cpfRule(v) || v === '' || !v]">
            <template v-slot:append>
              <v-progress-circular v-if="loading" indeterminate size="25" color="info"></v-progress-circular>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" dense>
          <v-row dense class="mb-4 mx-1 justify-end">
            <v-btn small id="pesquisa-paciente-1" :loading="loading" elevation="0" color="info" @click="
              get_query('get_paciente_m', pesquisa);
            get_empresas('get_empresas', [1, '', '']);
                          " dark class="mr-1">
              <v-icon left small>mdi-account-search</v-icon>
              Pesquisar
            </v-btn>
            <AddPaciente @onCreatedPaciente="onCreatedPaciente($event)" />
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- DATA TABLE -->
    <template v-if="pacientes.length > 0">

      <v-card outlined elevation="0" class="mx-auto pa-2" max-width="1100">
        <!-- DATATABLE -->
        <v-data-table 
          :headers="dataTable_header" 
          :items="pacientes" 
          show-expand 
          single-expand 
          :expanded.sync="expanded"
          item-key="cd_paciente" 
          :search="search_table" 
          locale="pt-BR" 
          no-data-text="Sem registros"
          no-results-text="Nenhum registro encontrado" 
          :custom-filter="customFilterPacientes" 
          :hide-default-footer="true"
        >
          <!-- CAMPO PESQUISA NA TABELA -->
          <!-- EXPANSÃO -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="background: #ddd">
              <!-- CARD PRINCIPAL -->
              <v-card outlined elevation="0" class="ma-1">
                <!-- TABS -->
                <v-toolbar color="secondary" dark flat dense>
                  <v-toolbar-title>
                    <span>{{ item.nm_paciente }} </span>
                    <span class="overline"> ({{ item.cd_paciente }})</span>
                  </v-toolbar-title>
                  <template v-slot:extension>
                    <v-tabs v-model="tab" align-with-title>
                      <v-tabs-slider color="info"></v-tabs-slider>
                      <v-tab> Paciente </v-tab>
                      <v-tab> Agendamentos </v-tab>
                      <v-tab> Agendar </v-tab>
                      <!--<v-tab> Agendar Exames </v-tab>-->
                     
                    </v-tabs>
                  </template>
                </v-toolbar>

                <!-- TABS ITEMS -->
                <v-tabs-items v-model="tab" class="pa-1">
                  <!-- TAB DE DADOS DO PACIENTE -->
                  <v-tab-item>
                    <v-card elevation="0" outlined class="pa-2 ma-1">
                      <v-row dense>
                        <v-col dense sm="6" cols="12">
                          <b>CPF do Cliente:</b>
                          <v-row dense>
                            <v-edit-dialog @save="update_paciente(item)" @cancel="cancel" @open="open" @close="close">
                              <div class="pl-2">
                                <u>{{ item.nr_cpf | formatCPF }}</u>
                              </div>
                              <template v-slot:input>
                                <v-text-field class="mt-5" v-model="item.nr_cpf" v-mask="'###.###.###-##'" :rules="[]"
                                  label="CPF">
                                </v-text-field>
                              </template>
                            </v-edit-dialog>
                            <v-btn class="ml-2" right icon depressed x-small @click="copyValue(item.nr_cpf, 'CPF')">
                              <v-icon>mdi mdi-content-copy</v-icon>
                            </v-btn>
                          </v-row>
                        </v-col>
                        <v-col dense sm="6" cols="12">
                          <b>Data Nascimento:</b>
                          <v-edit-dialog @save="update_paciente(item)" @cancel="cancel" @open="open" @close="close">
                            <div v-if="item.dt_nascimento" class="pl-2">
                              <span style="text-decoration:underline;"> {{ item.dt_nascimento | formatData }}
                              </span><v-icon small class="mb-1">mdi mdi-pencil</v-icon>
                            </div>
                            <template v-slot:input>
                              <v-text-field v-model="item.dt_nascimento" type="date">
                              </v-text-field>
                            </template>
                          </v-edit-dialog>
                          <!-- <div v-else class="pl-2"></div> -->
                        </v-col>
                        <!-- CAMPO DE N° CELULAR -->
                        <v-col dense sm="6" cols="6">
                          <b>Nº Celular : </b>
                          <b v-if="item.nr_ddd_celular === null || item.nr_celular === null"
                            class="red white--text">OBRIGATÓRIO</b>
                          <v-row dense align="center">
                            <v-edit-dialog @save="update_paciente(item)" @cancel="cancel" @open="open" @close="close">
                              <div class="pl-2">
                                <u>({{ item.nr_ddd_celular }})
                                  {{ item.nr_celular }}</u>
                              </div>
                              <template v-slot:input>
                                <v-row dense align="center">
                                  <v-col cols="2">
                                    <v-text-field class="mt-5 py-0 px-0" v-mask="'##'" v-model="item.nr_ddd_celular"
                                      :rules="[]" label="DDD">
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="10">
                                    <v-text-field class="mt-5 py-0 px-0" v-mask="'#########'" v-model="item.nr_celular"
                                      :rules="[]" label="Celular">
                                    </v-text-field>
                                  </v-col>
                                </v-row>
                              </template>
                            </v-edit-dialog>
                            <v-btn class="ml-2" right icon depressed x-small @click="copyPhone(item)">
                              <v-icon>mdi mdi-content-copy</v-icon>
                            </v-btn>
                          </v-row>
                        </v-col>
                        <!-- Campo telefone -->
                        <v-col dense sm="6" cols="6">
                          <b>Nº Telefone : </b>
                          <b v-if="item.nr_ddd_fone === null || item.nr_fone === null" class="red white--text">Sem
                            telefone</b>
                          <v-row dense align="center">
                            <v-edit-dialog @save="update_paciente(item)" @cancel="cancel" @open="open" @close="close">
                              <div class="pl-2">
                                <u>({{ item.nr_ddd_fone }})
                                  {{ item.nr_fone }}</u>
                              </div>

                              <template v-slot:input>
                                <v-row dense align="center">
                                  <v-col cols="2">
                                    <v-text-field class="mt-5 py-0 px-0" v-mask="'##'" v-model="item.nr_ddd_fone"
                                      :rules="[]" label="DDD">
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="10">
                                    <v-text-field class="mt-5 py-0 px-0" v-mask="'#########'" v-model="item.nr_fone"
                                      :rules="[]" label="Telefone">
                                    </v-text-field>
                                  </v-col>
                                </v-row>
                              </template>
                            </v-edit-dialog>
                            <v-btn class="ml-2" right icon depressed x-small @click="copyPhone(item)">
                              <v-icon>mdi mdi-content-copy</v-icon>
                            </v-btn>
                          </v-row>
                        </v-col>

                        <!-- Campo editável de Endereço -->
                        <v-col dense sm="6" cols="12">
                          <v-edit-dialog @save="update_paciente(item)" @cancel="cancel" @open="open" @close="close">
                            <b>Endereço : </b>
                            <div class="pl-2">
                              <u> {{ item.ds_endereco }} </u>
                            </div>
                            <template v-slot:input>
                              <v-text-field class="mt-5" v-model="item.ds_endereco" :rules="[]" label="Endereço">
                              </v-text-field>
                            </template>
                          </v-edit-dialog>
                          <v-edit-dialog @save="update_paciente(item)" @cancel="cancel" @open="open" @close="close">
                            <b>Nome : </b>
                            <div class="pl-2">
                              <u> {{ item.nm_paciente }} </u>
                            </div>
                            <template v-slot:input>
                              <v-text-field class="mt-5" v-model="item.nm_paciente" :rules="[]" label="Nome">
                              </v-text-field>
                            </template>
                          </v-edit-dialog>
                        </v-col>
                        <v-col dense sm="3" cols="12">
                          <b>Situação:</b>
                          <div class="pl-2">{{ item.tp_situacao }}</div>
                        </v-col>
                        <v-col dense sm="3" cols="12">
                          <b>Est. Civil:</b>
                          <div class="pl-2">{{ item.tp_estado_civil }}</div>
                        </v-col>
                        <v-col dense sm="2" cols="12">
                          <b>Sexo:</b>
                          <div class="pl-2">{{ item.tp_sexo }}</div>
                        </v-col>
                        <v-col dense sm="2" cols="12">
                          <div>
                            <b>Ativo:</b>
                            <v-chip v-if="item.sn_ativo == 'S'" class="ma-2" color="success" small>Sim</v-chip>
                            <v-chip v-else class="ma-2" small color="error">Não</v-chip>
                          </div>
                        </v-col>
                        <v-col dense sm="2" cols="12">
                          <b>Procedimentos realizados:</b>
                          <div>
                            <Detalhes :item="item" />
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card elevation="0" outlined class="pa-2 ma-1">
                      <v-row dense>
                        <v-col dense sm="6" cols="12">
                          <!-- Campo editável para Setor Origem de Atendimento -->
                          <v-edit-dialog @save="update_paciente(item)" @cancel="cancel" @open="open" @close="close">
                            <b>Multi Empresa:</b>
                            <div class="pl-2">
                              <u>{{ item.cd_multi_empresa }} -
                                {{
                                  filterDsEmpresaById(item.cd_multi_empresa)
                                }}</u>
                            </div>
                            <template v-slot:input>
                              <v-autocomplete :items="empresas" v-model="item.cd_multi_empresa"
                                item-value="cd_multi_empresa" item-text="ds_multi_empresa">
                              </v-autocomplete>
                            </template>
                          </v-edit-dialog>
                        </v-col>
                        <v-col dense sm="6" cols="12"> </v-col>
                      </v-row>
                    </v-card>
                    <v-card elevation="0" outlined class="pa-2 ma-1">
                      <v-row dense>
                        <v-col dense sm="6" cols="12" class="grey--text">
                          <!-- Campo editável para Detalhes do Sistem\ -->
                          <div>
                            <b>Cliente desde:</b>
                            {{ item.dt_cadastro | formatData }}
                          </div>
                        </v-col>
                        <v-col dense sm="6" cols="12"> </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>
                  <!-- TAB DE AGENDAMENTOS DO PACIENTE -->
                  <v-tab-item>
                    <agendamentos-do-paciente app="agendar" :paciente="item" :reload="reloadAgendamento" @tabEmit="setTab"
                      @selectedAgenda="selectAgenda" @selectedExame='selectExame' @reloaded="reloadAgendamento = false" />
                  </v-tab-item>
                  <!-- TAB AGENDAR PARA PACIENTE -->
                  <v-tab-item>
                    <agendar :paciente="item" :agendaAtual="selectedAgenda" @clearAgenda="() => (selectedAgenda = null)"
                      @reloadAgendamentos="reloadAgendamento = true" @volta_paciente="volta_aba_paciente()" />
                  </v-tab-item>
                  <!-- <v-tab-item>
                    <Exame :agenda="item" :exameAtual="exame" @clearAgenda="() => (exame = null)"
                      @reloadAgendamentos="reloadAgendamento = true" @volta_paciente="volta_aba_paciente()" />
                  </v-tab-item> -->
                
                </v-tabs-items>
              </v-card>
              <!-- FECHAMENTO CARD PRINCIPAL -->
            </td>
          </template>
          <!-- CUSTOMIZAR o CPF FORMATADO NO DATATABLE -->
          <template v-slot:[`item.cpf_formatado`]="props">
            <div>{{ props.item.nr_cpf | formatCPF }}</div>
          </template>

          <!-- FORMATAR A DATA PADRAO BRASIL NO DATATABLE -->
          <template v-slot:[`item.dt_nascimento`]="props">
            <div v-if="props.item.dt_nascimento">
              {{ props.item.dt_nascimento | formatData }}
            </div>
            <div v-else></div>
          </template>

          <!-- FORMATAR A DATA PADRAO BRASIL NO DATATABLE -->
          <template v-slot:[`item.nr_celular`]="props">
            <div>
              ({{ props.item.nr_ddd_celular }}) {{ props.item.nr_celular }}
            </div>
          </template>
          <template v-slot:[`item.nr_fone`]="props">
            <div>
              ({{ props.item.nr_ddd_fone }}) {{ props.item.nr_fone }}
            </div>
          </template>
        </v-data-table>
        <!-- alertas -->
        <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
          {{ snackText }}
          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" text @click="snack = false"> Fechar </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import AppEtlService from "../../service/AppEtlService";
// import { mapGetters } from 'vuex'
const api = new AppEtlService();

export default {
  name: "Agendamentos",
  components: {
    AgendamentosDoPaciente: () => import("../components/AgendamentosDoPaciente"),
    Agendar: () => import("../components/Agendar"),
    AddPaciente: () => import("../components/AddPaciente.vue"),
    Detalhes: () => import("../components/Detalhes.vue"),
    // Exame: () => import('../components/AgendarExame.vue'),
   
  },
  data: () => ({
    reloadAgendamento: false,
    pesquisa: "",
    pacientes: [],
    empresas: [],
    nome: "",
    cpf: "",
    dt_nascimento: "",
    codigo: "",
    editTelefoneDiag: false,
    search_table: "",
    loading: false,
    tab: null,
    selectedAgenda: null,
    exame:null,
    atendimentos: [],
    convenios: [],
    setores: [],
    medicos: [],
    expanded: [],
    snack: false,
    singleExpand: true,
    snackColor: "",
    snackText: "",
    ativar: null,
    //max50chars: v => v.length <= 50 || 'Input too long!',
    pagination: {},
    dataTable_header: [
      { text: "Cód.", align: "start", sortable: false, value: "cd_paciente" },
      { text: "Cliente", value: "nm_paciente" },
      { text: "CPF", value: "cpf_formatado" },
      { text: "Nascimento", value: "dt_nascimento" },
      { text: "Celular", value: "nr_celular" },
      { text: "Telefone", value: 'nr_fone' },
    ],
  }),
  methods: {
    selectAgenda(val) {
      this.selectedAgenda = val;
    },
    selectExame(val){
      this.exame = val;
    },
    setTab(val) {
      this.tab = val;
    },
    cpfRule(input) {
      return input?.length === 14 && this.isValidCPF(input);
    },
    isValidCPF(strCPF) {
      const getNextDigit = (lstValue, curValue, curIdx) =>
        lstValue + curValue * (9 - curIdx);
      let numValues = Array.from(strCPF.match(/\d/g), Number);
      let uniqueValues = new Set(numValues);
      let [, , ...arrCalc] = numValues.reverse();

      for (let i = 0; i < 2; i++) {
        arrCalc = [(arrCalc.reduce(getNextDigit, 0) % 11) % 10, ...arrCalc];
      }

      return (
        arrCalc.toString() === numValues.toString() && uniqueValues.size > 1
      );
    },
    copyPhone(item) {
      let rawNum = item.nr_ddd_celular + item.nr_celular;
      let value = Array.from(rawNum.match(/\d/g), Number).join("");

      this.copyValue(value, "N° celular");
    },
    copyValue(value, label) {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = `${label} copiado para a área de transferência.`;
      try {
        navigator.clipboard.writeText(value);
      } catch {
        this.unsecuredCopyToClipboard(value);
      }
    },
    unsecuredCopyToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    },
    onCreatedPaciente(value) {
      this.cpf = value.nr_cpf;
      this.nome = value.data.nr_nome;
      this.codigo = value.data.id;
      this.dt_nascimento = value.data.dt_nascimento;
      this.get_query_id_paciente([value.data.id]);
    },
    async update_paciente(obj) {
      let data = [
        obj.nm_paciente,
        obj.tp_sexo,
        obj.ds_endereco,
        obj.dt_nascimento?.split("T")[0]?.split("-")?.reverse()?.join("."),
        obj.nm_mae,
        obj.cd_cidadania,
        obj.cd_profissao,
        obj.nr_cpf.match(/\d/g).join(""),
        obj.nr_identidade,
        obj.ds_om_identidade,
        obj.email,
        obj.cd_multi_empresa,
        parseInt(obj.nr_ddd_celular),
        obj.nr_celular,
        parseInt(obj.nr_ddd_fone),
        obj.nr_fone,
        obj.cd_paciente,
      ];

      this.loading = true;
      return await api
        .put_api(
          "etl/query-put/?cod_query=put_paciente_dp",
          JSON.stringify(data)
        )
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            alert("Dado alterado com sucesso.");
          } else alert("Houve algum erro ao alterar os dados.");
        })
        .catch((err) => alert(err))
        .finally(() => (this.loading = false));
    },
    save() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },
    cancel() {
      this.snack = true; this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    close() {
      //console.log('Dialog closed')
    },

    customFilterPacientes(value, search, item) {
      return (
        value != null &&
        search != null &&
        item != null &&
        (item.cd_paciente
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 ||
          item.nm_paciente
            .toString()
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1 ||
          item.nr_celular
            .toString()
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1 ||
          item.dt_nascimento
            ?.split("T")[0]
            ?.replaceAll("-", ".")
            ?.indexOf(search.toLowerCase()) !== -1)
      );
    },
    async get_query() {
      let paciente = this.paciente.map((e, i) =>
        e?.length == 0 && i > 1 ? "%" : e
      );
      if (paciente.every((chr) => !chr || chr === "%")) {
        alert("Campo de pesquisa vazio!");
        return;
      }

      this.loading = true;
      return await api
        .get_query("get_paciente_m", paciente)
        .then((response) => {
          this.pacientes = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async get_query_id_paciente(query) {
      return await api
        .get_query("get_paciente_id", query)
        .then((response) => {
          this.pacientes = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async get_query_nome_niver() {
      let url = "get_paciente_nn";
      let params = [this.nome || "%", this.dt_nascimento, this.dt_nascimento];
      if (!this.dt_nascimento) {
        url = "get_paciente_";
        params = [this.nome || "%"];
      }
      return await api
        .get_query(url, params)
        .then((response) => {
          this.pacientes = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async get_empresas(pesquisa, empresas = [1, "", ""]) {
      this.loading = true;
      return await api
        .get_query(pesquisa, empresas)
        .then((response) => {
          this.empresas = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filterDsEmpresaById(id) {
      try {
        const item = this.empresas.filter((i) => i.cd_multi_empresa === id);
        return item.shift().ds_multi_empresa;
      } catch {
        return "";
      }
    },
    volta_aba_paciente() {
      this.tab = 0
    },

  },

  computed: {

    paciente() {
      let cpf = this.cpf?.match(/\d/g)?.join("") || "";
      let nascimento = this.nascimento;

      try {
        nascimento = nascimento.split("T");
        return [
          `${nascimento[0] || ""}`,
          `${nascimento[0] || ""}`,
          cpf || "",
          `${this.nome || ""}`,
          `${this.codigo || ""}`,
        ];
      } catch {
        return [
          `${nascimento || ""}`,
          `${nascimento || ""}`,
          cpf || "",
          `${this.nome || ""}`,
          `${this.codigo || ""}`,
        ];
      }
    },
  },

};
</script>
